<template>
  <div>
    <modal :name="modal_name" class="final-modal measure-modal popup-modal-all-width-height-height-430" transition="nice-modal-fade">
      <div class="v-modal-content" style="border-radius: 15px;">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">EDIT UNIT OF MEASUREMENT</span>
          <button
            type="button"
            class="closeModal"
            aria-label="Close"
            @click="hideEditMeasurement()"
          >
            <span aria-hidden="true">
              <i class="icon icon-close"></i>
            </span>
          </button>
        </div>
        <div class="general-section" style="padding: 15px 0px;">
        <div class="v-modal-body pt-0 pb-0">
          <div class="d-form">
            <div class="m-6 mb-13">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group pt-2">
                    <span class="text-secondary fs-14 font-600">
                      Enter Measurement
                      <sup>★</sup>
                    </span>
                    <input
                      maxlength="19"
                      name="measurement"
                      style="padding-top:0px !important"
                      v-model="measurement.name"
                      data-vv-as="Measurement"
                      class="inputContact modal-bg"
                      type="text"
                      v-validate="'required'"
                      placeholder="Enter Measurement"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                  </div>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="nameExists == true"
                  >{{ error_message}}</span>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('measurement')"
                  >{{ errors.first('measurement')}}</span>
                </div>
                <div class="col-lg-6">
                  <div class="form-group pt-2">
                    <span class="text-secondary fs-14 font-600">
                      Enter Unit of Measurement (UM)
                      <sup>★</sup>
                    </span>
                    <input
                      maxlength="19"
                      name="abbreviation_um"
                      style="padding-top:0px !important"
                      v-validate="'required'"
                      data-vv-as="Abbreviation UM"
                      v-model="measurement.abbreviation_um"
                      class="inputContact modal-bg"
                      type="text"
                      placeholder="Enter Unit of Measurement (UM)"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                  </div>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('abbreviation_um')"
                  >{{ errors.first('abbreviation_um')}}</span>
                </div>
              </div>
              <!-- <hr style="margin-top: 20px;border-top: 1px solid #30303159;margin-bottom: 13px;" /> -->
              <h6 style="color: #303031;font-size: 15px;font-weight: 600;margin: 10px 0px 5px;">
                Conversion
                Unit
              </h6>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group pt-2">
                    <span class="text-secondary fs-14 font-600">
                      Enter No. of Unit
                      <sup>★</sup>
                    </span>
                    <input
                      name="no_of_unit"
                      style="padding-top:0px !important"
                      v-validate="'required|numeric'"
                      data-vv-as="No. of Unit"
                      v-model="measurement.conversion_unit.no_of_unit"
                      class="inputContact modal-bg"
                      type="text"
                      placeholder="Enter No. of Unit"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                  </div>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('no_of_unit')"
                  >{{ errors.first('no_of_unit')}}</span>
                </div>
                <div class="col-lg-6">
                  <div class="form-group pt-2">
                    <span class="text-secondary fs-14 font-600">
                      Abbreviation Unit of Measurement (UM)
                      <sup>★</sup>
                    </span>
                    <input
                      readonly
                      maxlength="19"
                      name="abbreviation"
                      style="padding-top:0px !important"
                      data-vv-as="Abbreviation UM"
                      v-model="measurement.conversion_unit.abbreviation"
                      class="inputContact modal-bg"
                      type="text"
                      placeholder="Abbreviation Unit of Measurement (UM)"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                  </div>
                  <!-- <span class="invalid-feedback-form text-danger fs-13"
                  v-show="errors.has('abbreviation')">{{ errors.first('abbreviation')}}</span>-->
                </div>
                <div class="col-lg-6">
                  <div class="form-group pt-2">
                    <span class="text-secondary fs-14 font-600">
                      Conversion Value
                      <sup>★</sup>
                    </span>
                    <input
                      name="conversion_value"
                      style="padding-top:0px !important"
                      data-vv-as="Conversion Value"
                      v-validate="'required'"
                      v-model="measurement.conversion_unit.conversion_value"
                      class="inputContact modal-bg"
                      type="text"
                      placeholder="Conversion Value"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                  </div>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('conversion_value')"
                  >{{ errors.first('conversion_value')}}</span>
                </div>
                <div class="col-lg-6">
                  <span class="text-secondary fs-14 font-600" style="position: relative;top: 8px;">
                    Unit of Measurement (UM)
                    <sup>★</sup>
                  </span>
                  <!-- <v-select @input="updateAbbreviation" :filterable="true"
                                        v-model="measurement.conversion_unit.to_unit" :options="measurements"
                                        @search="loadMeasurements" data-vv-as="Unit of Measurement" name="to_unit"
                                        v-validate="'required'" label="name"
                                        placeholder="Select Unit of Measurement (UM)"
                  class="recipe-select max-select measurevselect clearNone" />-->
                  <multiselect
                    data-vv-as="UM"
                    v-validate="'required'"
                    name="to_unit"
                    v-model="measurement.conversion_unit.to_unit"
                    class="digi-multiselect"
                    id="ajax"
                    label="name"
                    placeholder="Select Unit of Measurement (UM)"
                    :options="measurements"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="40"
                    :max-height="200"
                    :show-no-results="true"
                    :hide-selected="false"
                    @search-change="loadMeasurementsSearch"
                    @close="updateAbbreviation"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        <span>{{ option.name }}</span>
                        <span class="custom__remove" @click="remove(option)">❌</span>
                      </span>
                    </template>
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfList" />
                    </template>
                    <span
                      slot="noResult"
                      class="font-weight-bold text-center d-block"
                    >No Results Found.</span>
                  </multiselect>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('to_unit')"
                  >{{ errors.first('to_unit')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="v-modal-dialog-actions modal-bg" style="border-radius: 0px 0px 15px 15px;">
          <a class="btn btn-link btn-smm" @click="hideEditMeasurement()">CANCEL</a>
          <button class="btn btn-secondary btn-smm text-uppercase" @click="editMeasurement()">UPDATE</button>
        </div>
      </div>
    </modal>
    <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
  </div>
</template>
<script>
import ScmServices from '../mixins/scm.js'
import { SweetModal } from 'sweet-modal-vue'
export default {
  props: ['modal_name', 'measurement_detail'],
  mixins: [ScmServices],
  data() {
    return {
      success_msg: 'Measurement Updated Successfully',
      warning_msg: 'Failed to Update Measurement',
      measurements: [],
      error_message: '',
      nameExists: false,
      isLoading: false,
      isVisible: false,
      count: 0,
      limit: 10,
      scrollable: false,
      unitMeasure: [],
      measurement: this.measurement_detail
    }
  },
  methods: {
    async loadMeasurementsSearch(search) {
      this.isLoading = true
      let response = await this.getScmMeasurements({
        search_key: search,
        skip: 0,
        limit: 10
      })
      this.measurements = response.unit_of_measurements
      this.count = this.measurements.length
      this.isLoading = false
      this.scrollable = false
    },
    async reachedEndOfList(reached) {
      this.isVisible = reached
      if (reached) {
        if (this.scrollable == false) {
          this.isLoading = true
          let response = await this.getScmMeasurements({
            skip: this.count,
            limit: 10
          })
          this.unitMeasure = response.unit_of_measurements.length
          if (response.skip == this.count) {
            if (this.unitMeasure > 0) {
              let tempFiles = response.unit_of_measurements
              tempFiles.forEach(el => {
                this.measurements.push(el)
              })
              this.count += response.unit_of_measurements.length
              this.isLoading = false
            } else {
              this.scrollable = true
              this.isLoading = false
            }
          }
          this.isLoading = false
        }
      }
    },
    hideEditMeasurement() {
      this.$modal.hide(this.modal_name)
      setTimeout(() => {
        this.$emit('closeMeasure')
      }, 3000)
      ;(this.nameExists = false),
        (this.unitMeasure = []),
        (this.scrollable = false),
        (this.count = 0)
    },
    async editMeasurement() {
      try {
        this.$validator.validateAll().then(result => {
          if (result == true) {
            this.updatingMeasurement(
              this.update_measurement
            ).then(response => {})
            this.$refs.success_modal.open()
            setTimeout(() => {
              this.$refs.success_modal.close()
              this.nameExists = false
            }, 2000)
          }
        })
      } catch (error) {
        this.$log.debug(error)
      }
    },
    async updatingMeasurement(data) {
      return new Promise((resolve, reject) => {
        this.$http
          .put('/scm/update_unit_of_measurement', data)
          .then(response => {
            if (response.data.status_id == 1) {
              resolve(response.data)
              this.hideEditMeasurement()
            } else {
              reject(new Error(response.data.reason))
              this.error_message = response.data.reason
              this.nameExists = true
            }
          })
          .catch(error => {
            reject()
          })
      })
    },
    async loadMeasurements(search) {
      this.isLoading = true
      let response = await this.getScmMeasurements({
        search_key: search,
        skip: 0,
        limit: 10
      })
      this.count = 0
      //   this.measurements = [],
      this.unitMeasure = []
      this.isLoading = false
    },
    updateAbbreviation() {
      if (this.measurement.conversion_unit.to_unit) {
        this.measurement.conversion_unit.abbreviation =
          this.measurement.conversion_unit.to_unit.conversion_unit.abbreviation
      }
    }
  },
  computed: {
    update_measurement() {
      return {
        name: this.measurement.name,
        abbreviation_um: this.measurement.abbreviation_um,
        no_of_unit: this.measurement.conversion_unit.no_of_unit,
        abbreviation: this.measurement.conversion_unit.abbreviation,
        conversion_value: this.measurement.conversion_unit.conversion_value,
        to_unit: this.measurement.conversion_unit.to_unit,
        _id: this.measurement._id
      }
    }
  },
  components: {
    SweetModal
  },
  created() {
    this.loadMeasurements('')
    const dict = {
      custom: {
        measurement: {
          required: () => 'Measeurement Is Required',
          numeric: () => 'Measurement contains only numeric'
        },
        abbreviation_um: {
          required: () => 'Abbreviation UM Is Required'
        },
        no_of_unit: {
          required: () => 'No of Unit Is Required',
          numeric: () => 'No of Unit contains only numeric'
        },
        abbreviation: {
          required: () => 'Abbreviation UM Is Required'
        },
        conversion_value: {
          required: () => 'Conversion Value Is Required',
          numeric: () => 'Conversion Value contains only numeric'
        },
        to_unit: {
          required: () => 'UM Is Required'
        }
      }
    }
  },
  beforeMount() {
    this.measurement = JSON.parse(JSON.stringify(this.measurement))
  },
  beforeDestroy() {
    this.$validator.pause()
  }
}
</script>
<style scoped>
.border-radius {
  border-radius: 12px;
}

.quantity-details {
  flex: 1 1 250px;
  font-size: 16px;
  text-align: center;
}

.bx-shadow {
  box-shadow: 3px 0 0px -2px #989898;
}

.final-cost {
  border-top: solid 1px #989898;
  border-bottom: solid 1px #989898;
}

.inputContact {
  border-bottom: solid 1px #a9aaad !important;
}

.v-select.um-select {
  width: 100px;
  height: 25px !important;
  line-height: 25px !important;
}

.v-select.um-select.single.searchable .dropdown-toggle {
  height: 25px;
  border-bottom: solid 1px #ffffff !important;
  background-position: right 8px center !important;
  background-image: url('https://cdn.digicollect.com/cdn/posv2/downarrow-white.svg') !important;
}

.v-select.um-select input[type='search'],
.v-select.um-select input[type='search']:focus {
  height: 20px !important;
  line-height: 20px !important;
  padding: 0px !important;
  color: #fff !important;
}

.v-select.um-select input[type='search'].form-control:-moz-placeholder,
.dropdown.v-select.um-select input.form-controlt:-moz-placeholder,
.v-select.um-select input[type='search'].form-control:-ms-input-placeholder,
.dropdown.v-select.um-select
  input.form-control:-ms-input-placeholder
  .v-select.um-select
  input[type='search'].form-control::-webkit-input-placeholder,
.dropdown.v-select.um-select input.form-control::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.v-select.um-select input[type='search']:-moz-placeholder,
.v-select.um-select input[type='search']:-ms-input-placeholder,
.v-select.um-select input[type='search']::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.v-select.um-select .selected-tag {
  color: #fff !important;
  height: 18px !important;
  line-height: 18px !important;
}

.v-select.text-um .selected-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  width: 90px;
}

.v-select.um-select .vs__selected-options {
  height: 25px !important;
  line-height: 25px !important;
}

.v-select.um-select-version.single.searchable .dropdown-toggle {
  height: 25px;
  border-bottom: solid 1px #00448b !important;
  background-position: right 8px center !important;
  background-image: url('https://cdn.digicollect.com/cdn/posv2/downarrow-white.svg') !important;
}

.v-select.measurevselect .dropdown-menu {
  max-height: 312px !important;
}

.v-select.max-select.open .dropdown-menu {
  max-height: 312px !important;
}

.v-select.um-select {
  width: 100px;
  height: 25px !important;
  line-height: 25px !important;
}

.v-select.um-select.single.searchable .dropdown-toggle {
  height: 25px;
  border-bottom: solid 1px #ffffff !important;
  background-position: right 8px center !important;
  background-image: url('https://cdn.digicollect.com/cdn/posv2/downarrow-white.svg') !important;
}

.v-select.um-select input[type='search'],
.v-select.um-select input[type='search']:focus {
  height: 20px !important;
  line-height: 20px !important;
  padding: 0px !important;
  color: #fff !important;
}

.v-select.um-select input[type='search'].form-control:-moz-placeholder,
.dropdown.v-select.um-select input.form-controlt:-moz-placeholder,
.v-select.um-select input[type='search'].form-control:-ms-input-placeholder,
.dropdown.v-select.um-select
  input.form-control:-ms-input-placeholder
  .v-select.um-select
  input[type='search'].form-control::-webkit-input-placeholder,
.dropdown.v-select.um-select input.form-control::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.v-select.um-select input[type='search']:-moz-placeholder,
.v-select.um-select input[type='search']:-ms-input-placeholder,
.v-select.um-select input[type='search']::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.v-select.um-select .selected-tag {
  color: #fff !important;
  height: 18px !important;
  line-height: 18px !important;
}

.v-select.text-um .selected-tag {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90px;
  width: 90px;
}

.v-select.um-select .vs__selected-options {
  height: 25px !important;
  line-height: 25px !important;
}

.v-select.um-select-version.single.searchable .dropdown-toggle {
  height: 25px;
  border-bottom: solid 1px #00448b !important;
  background-position: right 8px center !important;
  background-image: url('https://cdn.digicollect.com/cdn/posv2/downarrow-white.svg') !important;
}

.v-select.measurevselect .dropdown-menu {
  max-height: 312px !important;
}

.v-select.max-select.open .dropdown-menu {
  max-height: 312px !important;
}
</style>
